import * as React from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { Header, Menu, Loader } from "semantic-ui-react";
import { Rights_Programme } from "../../generated";
import { useRoutePreload } from "../../hooks/useRoutePreload";

interface IProgrammeHeaderProps {
  loading?: boolean;
  referenceNo?: string | null;
  upn?: string | null;
  type?: string | null;
  title?: string | null;
  rights?: any[] | null;
}

export const ProgrammeHeader: React.FC<IProgrammeHeaderProps> = (
  props: IProgrammeHeaderProps
) => {
  let addresslist = useRouteMatch(
    `/programme/${props.referenceNo}/addresslist/${props.upn}`
  );
  let participations = useRouteMatch(
    `/programme/${props.referenceNo}/participants/${props.upn}`
  );
  let dif = useRouteMatch(`/programme/${props.referenceNo}/dif/${props.upn}`);
  let tiflist = useRouteMatch(`/programme/${props.referenceNo}/tif/${props.upn}`);
  let eformlist = useRouteMatch(`/programme/${props.referenceNo}/eforms/${props.upn}`);
  let emergencycontacts = useRouteMatch(`/programme/${props.referenceNo}/emergencycontacts/${props.upn}`);

  //preload all route bundles for programme views
  useRoutePreload((path) => path.indexOf("/programme/") >= 0);

  return (
    <>
      <Header>
        Programme: {props.referenceNo}
        <Header.Subheader>
          {props.title ? props.title : props.type}
        </Header.Subheader>
      </Header>
      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          icon="world"
          active={
            !addresslist && !participations && !dif && !tiflist && !eformlist && !emergencycontacts
          }
          as={Link}
          to={`/programme/${props.referenceNo}/${props.upn}`}
        />
        {props.rights &&
          props.rights.some(
            (right) =>
              right == Rights_Programme.ParticipationsView ||
              right == Rights_Programme.ParticipationsEdit
          ) && (
            <Menu.Item
              name="Participants"
              icon="users"
              as={Link}
              active={!!participations}
              to={`/programme/${props.referenceNo}/participants/${props.upn}`}
            />
          )}
        {props.rights &&
          props.rights.some(
            (right) =>
              right == Rights_Programme.ParticipantViewSensitive ||
              right == Rights_Programme.ParticipantView ||
              right == Rights_Programme.ParticipantEdit
          ) && (
            <Menu.Item
              name="Address List"
              icon="address card"
              as={Link}
              active={!!addresslist}
              to={`/programme/${props.referenceNo}/addresslist/${props.upn}`}
            />
          )}
        {
          // 2023-03-17 GMR: Only "sensitive" are allowed! "Edit" can see the Health-Form, so no need to see DIF
          props.rights &&
          props.rights.some(
            (right) =>
              right ==
              Rights_Programme.ParticipantViewSensitive /*|| right == Rights_Programme.ParticipantEdit*/ ||
              right ==
              Rights_Programme.InvitationParticipantViewSensitive /*|| right == Rights_Programme.InvitationParticipantEdit*/
          ) && (
            <Menu.Item
              name="Delegation Info"
              icon="id badge"
              as={Link}
              active={!!dif}
              to={`/programme/${props.referenceNo}/dif/${props.upn}`}
            />
          )
        }
        {props.rights &&
          props.rights.some(
            (right) =>
              right == Rights_Programme.ParticipantViewSensitive ||
              right == Rights_Programme.ParticipantView ||
              right == Rights_Programme.ParticipantEdit
          ) && (
            <Menu.Item
              name="Travel Info"
              icon="id badge outline"
              as={Link}
              active={!!tiflist}
              to={`/programme/${props.referenceNo}/tif/${props.upn}`}
            />
          )}
        {props.rights &&
          props.rights.some(
            (right) =>
              right == Rights_Programme.ParticipantViewSensitive ||
              right == Rights_Programme.ParticipantEdit ||
              right == Rights_Programme.InvitationParticipantViewSensitive ||
              right == Rights_Programme.InvitationParticipantEdit
          ) && (
            <Menu.Item
              name="EForms List"
              icon="folder open outline"
              as={Link}
              active={!!eformlist}
              to={`/programme/${props.referenceNo}/eforms/${props.upn}`}
            />
          )}
        {props.rights &&
          props.rights.some(
            (right) =>
              right == Rights_Programme.ParticipantViewSensitive ||
              right == Rights_Programme.ParticipantEdit ||
              right == Rights_Programme.InvitationParticipantViewSensitive ||
              right == Rights_Programme.InvitationParticipantEdit
          ) && (
            <Menu.Item
            name="Emergency Contacts"
            icon="phone"
            as={Link}
            active={!!emergencycontacts}
            to={`/programme/${props.referenceNo}/emergencycontacts/${props.upn}`}
          />
        )}
        {props.loading === true && (
          <Menu.Item>
            <Loader active inline="centered" size="tiny" />
          </Menu.Item>
        )}
      </Menu>
    </>
  );
};

export default ProgrammeHeader;
