import * as React from "react";
import loadable from "@loadable/component";
import { Switch, Redirect } from "react-router";
import { ToastProvider } from "react-toast-notifications";
import { QueryClientProvider } from "@tanstack/react-query";

import { UserContext } from "./context/UserContext";
import { RightsContext } from "./context/RightsContext";
import { LandingLayoutRoute } from "./views/layouts/LandingPageLayout";
import { UserLayoutRoute } from "./views/layouts/UserLayout";
import { AnonLayoutRoute } from "./views/layouts/AnonLayout";
import NotFoundRoute from "./views/NotFoundRoute";

import "./styles/index.less";
import { LoadingScreen } from "./views/LoadingScreen";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { ToastMessage } from "./components/ToastMessage";
import { CatchApiErrors } from "./components/CatchApiErrors";
import { ToastContainer } from "./components/ToastContainer";
import { NavigationContext } from "./context/NavigationContext";
import { SearchContext } from "./context/SearchContext";
import { flatRoutes, anonRoutes } from "./routes";
import { MobileNavigation } from "./components/MobileNavigation";
import { MsalProvider } from "@azure/msal-react";
import { authProvider } from "./authProvider";
import { Route } from "react-router-dom";
import { queryClient } from "./queryClient";
import { Media, MediaContextProvider, mediaStyles } from "./AppMedia";

const fallback = {
  fallback: <LoadingScreen />,
};

const getPath = (path?: string | null, urlParam?: string | null) =>
  path ? path + (urlParam ?? "") : undefined;

const LandingPage = loadable(
  () => import(/* webpackPrefetch: true */ "./views/LandingPage"),
  fallback
);

const Register = loadable(() => import("./views/Register"), fallback);

export default class App extends React.Component {
  static displayName = App.name;
  render() {
    const routes = flatRoutes // app routes from routes structure
      .filter((r) => r.component || r.render);
    return (
      <ErrorBoundary>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <QueryClientProvider client={queryClient}>
            <ToastProvider
              autoDismissTimeout={6000}
              components={{
                Toast: ToastMessage,
                ToastContainer: ToastContainer,
              }}
              placement="top-center"
            >
              <NavigationContext>
                <MsalProvider instance={authProvider}>
                  <UserContext>
                    <CatchApiErrors>
                      <RightsContext>
                        <SearchContext>
                          <Switch>
                            <Route
                              path="/Account/SignOut"
                              render={() => <Redirect to="/" />}
                            />
                            <LandingLayoutRoute
                              path="/"
                              component={LandingPage}
                              exact
                            />
                            <LandingLayoutRoute
                              name="Create your CISV Account"
                              path="/register"
                              component={Register}
                            />
                            {anonRoutes // app routes from routes structure
                              .map((route) => (
                                <AnonLayoutRoute
                                  key={route.path + (route.urlParam || "")}
                                  exact={
                                    route.exact == undefined
                                      ? !!(
                                          route.path &&
                                          routes.some(
                                            (r) =>
                                              r != route &&
                                              r.path &&
                                              r.path.startsWith(route.path!)
                                          )
                                        )
                                      : route.exact
                                  }
                                  {...route}
                                  path={getPath(route.path, route.urlParam)}
                                ></AnonLayoutRoute>
                              ))}
                            {routes // app routes from routes structure
                              .map((route) => (
                                <UserLayoutRoute
                                  key={route.path + (route.urlParam || "")}
                                  exact={
                                    route.exact == undefined
                                      ? !!(
                                          route.path &&
                                          routes.some(
                                            (r) =>
                                              r != route &&
                                              r.path &&
                                              r.path.startsWith(route.path!)
                                          )
                                        )
                                      : route.exact
                                  }
                                  {...route}
                                  path={getPath(route.path, route.urlParam)}
                                ></UserLayoutRoute>
                              ))}
                            <NotFoundRoute />
                          </Switch>
                          <Media at="mobile">
                            <MobileNavigation />
                          </Media>
                        </SearchContext>
                      </RightsContext>
                    </CatchApiErrors>
                  </UserContext>
                </MsalProvider>
              </NavigationContext>
            </ToastProvider>
          </QueryClientProvider>
        </MediaContextProvider>
      </ErrorBoundary>
    );
  }
}
