import * as React from "react";
import { Sidebar, SidebarProps, Dimmer } from "semantic-ui-react";
import "./SideOverlay.less";

interface ISideOverlayProps extends SidebarProps {
  dimmed?: boolean;
  render?: () => React.ReactNode;
}

export const SideOverlay: React.FC<ISideOverlayProps> = ({
  onHide,
  ...props
}) => {
  return (
    <Sidebar.Pushable className="screen-overlay">
      {props.dimmed && (
        <Dimmer
          inverted
          style={{ zIndex: 99 }}
          active={props.visible}
          onClickOutside={onHide}
          page
        ></Dimmer>
      )}
      <Sidebar
        animation="overlay"
        vertical
        direction="right"
        width="wide"
        {...props}
      >
        {props.visible && (props.render ? props.render() : props.children)}
      </Sidebar>
    </Sidebar.Pushable>
  );
};
