import * as React from "react";
import ExampleTheme from "./themes/ExampleTheme";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { $generateNodesFromDOM } from "@lexical/html";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import "./styles.less";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { OnChangePlugin } from "./plugins/OnChangePlugin";
import {
  $createParagraphNode,
  $getRoot,
  $isDecoratorNode,
  $isElementNode,
  EditorState,
  LexicalEditor,
} from "lexical";
import classNames from "classnames";

export interface EditorProps {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (htmlValue?: string) => void;
}

const getInitialState = (editor: LexicalEditor, initialValue: string) => {
  editor.update(() => {
    // In the browser you can use the native DOMParser API to parse the HTML string.
    const parser = new DOMParser();
    const dom = parser.parseFromString(initialValue, "text/html");

    // Once you have the DOM instance it's easy to generate LexicalNodes.
    const nodes = $generateNodesFromDOM(editor, dom);

    const root = $getRoot();

    nodes.forEach((node, i) => {
      if ($isElementNode(node) || $isDecoratorNode(node)) {
        root.append(node);
      } else {
        const p = $createParagraphNode();
        p.append(node);
        root.append(p);
      }
    });
  });
};
const getEditorConfig = (
  initialValue: string,
  editable?: boolean
): InitialConfigType => ({
  editorState: (editor) => getInitialState(editor, initialValue),
  namespace: "",
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error: any) {
    throw error;
  },
  editable: editable,
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
});

export default function Editor({
  onChange,
  placeholder,
  value,
  disabled,
}: EditorProps) {
  const _onChange = React.useCallback((editorState: EditorState, html: string | null) => {
    onChange && onChange(html ?? undefined);
  },[onChange]);
  return (
    <LexicalComposer initialConfig={getEditorConfig(value ?? "", !disabled)}>
      <div className={classNames("editor-container", disabled && "readonly")}>
        {!disabled && <ToolbarPlugin />}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={
              placeholder ? (
                <div className="editor-placeholder">
                  {placeholder ?? "Enter some rich text..."}
                </div>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <OnChangePlugin onChange={_onChange} />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
        </div>
      </div>
    </LexicalComposer>
  );
}
