import * as React from "react";
import { Button } from 'semantic-ui-react';

interface ICancelButton {
  onCancelClick(dirty: boolean, isSubmitting: boolean): void;
  dirty: boolean;
  isSubmitting: boolean;
  disabled?: boolean
}

export const CancelButton: React.FC<ICancelButton> = (props: ICancelButton) => {
  return (
    <Button disabled={props.disabled} onClick={() => { props.onCancelClick(props.dirty, props.isSubmitting) }} floated="right" icon="cancel" size="mini" style={{ position: 'absolute', top: '1em', right: '1em' }} />
  );
};



