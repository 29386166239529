export enum CellDataType {
  ANY,
  STRING,
  NUMBER,
}

export interface ISpreadSheetColumn {
  header: string;
  type: CellDataType;
}

export interface ISpreadSheetData {
  columns: ISpreadSheetColumn[];
  rows: string[][];
}

export const saveSpreadsheetCSV = (
  fileName: string,
  data: ISpreadSheetData
) => {
  const rows = data.rows;
  const columns = data.columns;
  rows.unshift(columns.map((c) => c.header)); // include columns as header row
  const csvData = toCsvString(rows, columns);
  downloadFile(csvData, `${fileName}.csv`, "text/csv;charset=UTF-16LE;");
};

// Building the CSV from the Data two-dimensional array
// Each column is separated by ";" and new line "\n" for next row
export const toCsvString = (
  data: string[][],
  columns?: ISpreadSheetColumn[]
) => {
  var csvContent = "sep=;\n";
  data.forEach(function (infoArray, index) {
    const dataString = infoArray
      .map((cell, index) => {
        if (
          cell.includes &&
          (cell.includes('"') ||
            cell.includes(",") ||
            cell.includes(";") ||
            cell.includes("\n"))
        ) {
          /**
           * If a value contains a comma, a newline character or a double quote, then the string must be enclosed in double quotes. E.g: "Newline char in this field \n"
           * A double quote must be escaped with another double quote. E.g: "The double quote character "" is offensive."
           */
          cell = cell.replace(/"/g, '""');
          cell = `"${cell}"`;
        } else if (columns && columns[index].type == CellDataType.STRING) {
          cell = `="${cell}"`;
        }
        if (cell.search && cell.search("-") === 0) {
          cell = cell.replace("-", " -");
        }
        return cell;
      })
      .join(";");
    csvContent += index < data.length ? dataString + "\n" : dataString;
  });
  return csvContent;
};

// The download function takes a string, the filename and mimeType as parameters
// Scroll/look down at the bottom of this snippet to see how download is called
export const downloadFile = (
  content: string,
  fileName: string,
  mimeType: string
) => {
  var a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";

  var bom = decodeURIComponent("%EF%BB%BF"); // "\uFEFF\n";
  var csv = bom + content;

  var csvA = new Uint16Array(
    csv.split("").map(function (k, v) {
      return k.charCodeAt(0);
    })
  );

  if (URL && "download" in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([csvA], {
        type: mimeType,
      })
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href =
      "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
  }
};
