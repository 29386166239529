import * as React from "react";
import SearchApi from "../api/SearchApi";
import { useQuery } from "@tanstack/react-query";
import { cancelPromise } from "../utils/withAbortSignal";

export interface SearchMediaQuery {
  queryString?: string | null;
  entriesToTake?: number;
  mediaTag?: string | null;
  mediaYear?: string | null;
  programmeType?: string | null;
}

export default function useMediaSearch({
  queryString,
  entriesToTake,
}: SearchMediaQuery) {
  const [mediaTag, setMediaTag] = React.useState("");
  const [mediaYear, setMediaYear] = React.useState("");
  const [programmeType, setProgrammeType] = React.useState("");
  const searchMedia = useQuery(
    [
      "searchMediaGET",
      queryString,
      mediaTag,
      mediaYear,
      programmeType,
      entriesToTake,
    ],
    ({ signal }) =>
      cancelPromise(
        SearchApi.searchMediaGet(
          queryString!,
          entriesToTake,
          mediaTag,
          programmeType,
          mediaYear
        ),
        signal
      ),
    { enabled: !!queryString }
  );
  return {
    media: searchMedia.data && {
      name: "Media",
      results: searchMedia.data.map((c) => {
        const mediaCredit =
          c.additionalFields &&
          c.additionalFields.find((owner) => owner.id === "MediaCredit");
        const type =
          c.additionalFields &&
          c.additionalFields.find((type) => type.id === "ProgrammeType");

        return {
          key: c.url,
          url: c.url,
          description:
            type && mediaCredit
              ? `${type && type.description ? type.description : ""} ${
                  mediaCredit && mediaCredit.description
                    ? `by ${mediaCredit.description}`
                    : ""
                }`
              : c.mimeType,
          id: c.url,
          title: c.displayName,
          displayName: c.displayName,
          sourceLocation: c.sourceLocation,
          mimeType: c.mimeType,
          previewURL: c.previewURL,
          additionalFields: c.additionalFields,
        };
      }),
    },
    loading: searchMedia.isInitialLoading,
    setMediaTag,
    setMediaYear,
    setProgrammeType,
    mediaTag,
    mediaYear,
    programmeType,
  };
}
