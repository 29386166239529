import * as React from "react";
import RightsApi from "../api/RightsApi";
import { EEntityType } from "../generated";
import useUser from "../hooks/UseUser";
import { useQuery } from "@tanstack/react-query";

interface IRightsContextProps {
  children: React.ReactNode;
}

interface RightsContextState {
  loading: boolean;
  webapp: Set<string>;
  hasRight: (right: string, id?: string) => boolean;
}

export const RightsCtx = React.createContext<RightsContextState>({
  loading: true,
  webapp: new Set([]),
  hasRight: (right: string, id?: string) => false,
});

export const RightsConsumer = RightsCtx.Consumer;

const emptySet = new Set([]);

export const RightsContext: React.FC<IRightsContextProps> = (props) => {
  const userCtx = useUser();
  const getWebApp = useQuery(
    ["getRightsGET", userCtx.user && userCtx.user.loginID],
    async () => {
      const result = await RightsApi.getRightsGet(EEntityType.WebApp);
      const presult = await RightsApi.getRightsGet(EEntityType.Programme);
      const iresult = await RightsApi.getRightsGet(EEntityType.Invitation);
      return new Set([...result, ...presult, ...iresult]);
    }
  );
  const hasRight = (right: string, id?: string) => {
    if (!id) {
      return getWebApp.data ? getWebApp.data.has(right) : false;
    }
    return false;
  };
  return (
    <RightsCtx.Provider
      value={{
        hasRight,
        loading: getWebApp.isLoading,
        webapp: getWebApp.data || emptySet,
      }}
    >
      {props.children}
    </RightsCtx.Provider>
  );
};
