import * as React from "react";
import { ReactComponent as CisvLogo } from "../img/logo_white.svg";
import { Link } from "react-router-dom";
import { Col } from "react-flexbox-grid";
import { Menu, Dropdown, Label, Icon, Segment } from "semantic-ui-react";
import MenuSearch from "./MenuSearch";
import "./NavMenu.less";
import useUser from "../hooks/UseUser";
import useRights from "../hooks/UseRights";
import { Rights_WebApp } from "../generated";
import { ChangePassword } from "../views/Profile/ChangePassword";
import { Media } from "../AppMedia";

interface INavMenuProps {
  anonymous?: boolean;
}

export function UserDropdown({
  anonymous,
  setOpen,
}: {
  anonymous?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const userCtx = useUser();
  return (
    <>
      {!anonymous && userCtx.user ? (
        <Dropdown
          icon="user"
          item
          text={userCtx.user.displayName!}
          className="mr-5-divs"
        >
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/profile" name="profile" icon="user">
              My Profile
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="/profile/consent"
              name="consent"
              icon="shield"
            >
              Consent and communications
            </Dropdown.Item>
            <Dropdown.Item
              icon="key"
              name="changePassword"
              content="Change Password"
              onClick={() => setOpen && setOpen(true)}
            >
              Change password
            </Dropdown.Item>
            <Dropdown.Item icon="sign-out" onClick={() => userCtx.logout()}>
              Sign out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Menu.Item
          icon="sign-in"
          name="signin"
          content="Login"
          onClick={() => userCtx.login()}
        />
      )}
    </>
  );
}

export const NavMenu: React.FC<INavMenuProps> = (props) => {
  const rightsCtx = useRights();
  const [open, setOpen] = React.useState(false);
  function closeEdit(edit: boolean) {
    if (!edit) {
      setOpen(false);
    }
  }
  return (
    <header className="nav-menu">
      <Menu stackable borderless>
        <Col sm={2} style={{ height: "100%", minWidth: "12em" }}>
          <Menu.Item
            icon="home"
            className="logo"
            name="home"
            as={Link}
            to="/profile"
          >
            <div style={{ width: "12em" }}>
              <CisvLogo />
            </div>
          </Menu.Item>
          <Menu.Item className="print-only address-info">
            CISV International Ltd<br></br>
            167-169 Great Portland Street, 5th Floor, London, England, W1W 5PF
          </Menu.Item>
          <Menu.Item className="print-only contact-info">
            Company Registration: 3672838<br></br>
            Charity Registration: 1073308<br></br>
            E-mail: International@cisv.org<br></br>
            CISV International Ltd
          </Menu.Item>
        </Col>
        <Media greaterThanOrEqual="computer">
          {(className, renderChildren) =>
            renderChildren && (
              <>
                {!props.anonymous && (
                  <Menu.Item>
                    <MenuSearch />
                  </Menu.Item>
                )}

                <Menu.Menu position="right">
                  {rightsCtx.hasRight(Rights_WebApp.GlobalAdmin) && (
                    <Menu.Item>
                      <Label color="red">
                        <Icon name="globe" /> Global Admin
                      </Label>
                    </Menu.Item>
                  )}
                  <UserDropdown anonymous={props.anonymous} setOpen={setOpen} />
                </Menu.Menu>
              </>
            )
          }
        </Media>
      </Menu>

      <ChangePassword closeEdit={closeEdit} edit={open} />
    </header>
  );
};
