import * as React from "react";
import { ReactNode } from "react";
import { Form, FormInputProps, Segment, Icon } from "semantic-ui-react";

interface IFormFieldProps extends FormInputProps {
  information?: JSX.Element;
  optional?: boolean;
}

export const FormField: React.FC<IFormFieldProps> = ({ label, ...props }) => {
  const [infoVisible, setInfoVisible] = React.useState(false);
  return (
    <>
      <Form.Input
        label={
          <label>
            {label as ReactNode}
            {props.optional && (
              <span style={{ fontWeight: "lighter", color: "grey" }}>
                {" "}
                (optional)
              </span>
            )}
            {props.information && (
              <>
                {" "}
                <Icon
                  style={{ cursor: "pointer" }}
                  onClick={() => setInfoVisible(!infoVisible)}
                  name="question circle outline"
                />
              </>
            )}
          </label>
        }
        {...props}
      />
      {infoVisible && <Segment>{props.information}</Segment>}
    </>
  );
};
