import * as React from "react";
import { Formik, Form, FormikHelpers, Field, FieldProps } from "formik";
import * as Yup from "yup";
import {
  Button,
  Form as SemanticForm,
  Label,
  Header,
  List,
  Segment,
} from "semantic-ui-react";
import { Row, Col, ColProps } from "react-flexbox-grid";
import {
  SimpleFormCheckBoxFromOptions,
  SimpleFormFieldArray,
} from "./FormComponents";
import { optionsItem, IFormField, IFormFieldProps } from ".";
import { FormField } from "./FormField";
import { MemoField } from "./MemoField";
import { Prompt } from "react-router";
import { notNullOrUndefined } from "../../utils/notNullOrUndefined";

export interface ISimpleFormProps {
  intialValues?: IInitialValues;
  formSchema: (IFormField | null)[];
  onSubmit:
    | ((values: any, formikActions: FormikHelpers<any>) => Promise<any>)
    | ((values: any, formikActions: FormikHelpers<any>) => void);
  resetOnSubmit?: boolean;
  className?: any;
  loading?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  submitText?: React.ReactChild;
  renderSubmitButtons?: (props: {
    disabled: boolean;
    isValidating: boolean;
    isSubmitting: boolean;
    dirty: boolean;
    submitForm: () => void;
    values: any;
  }) => JSX.Element;
}

function noop() {
  // no operation
}

function getValueOrDefault<TValue>(
  intialValue: TValue | undefined | null,
  defaultValue: TValue | undefined | null
) {
  if (intialValue != null && intialValue != undefined) {
    return intialValue;
  }
  if (defaultValue != null && defaultValue != undefined) {
    return defaultValue;
  }
  return "";
}

export interface IInitialValues {
  [propName: string]: any;
}

function getInitialValues(schema: IFormField[], values?: IInitialValues) {
  const initialValues: IInitialValues = {};
  schema.forEach((x: IFormField) => {
    let fieldsInitial: IInitialValues[] = [];
    if ((x.fieldArrayFields || x.fields) && x.component != List) {
      if (x.fieldArrayFields) {
        if (!values || !values[x.props.name]) {
          var initialRowData: IInitialValues = {};
          x.fieldArrayFields &&
            x.fieldArrayFields.forEach((y: IFormField) => {
              if (
                y.component !== Label &&
                y.component !== Header &&
                y.component !== List
              ) {
                initialRowData[y.props.name] = y.props.defaultValue || "";
                fieldsInitial.push();
              }
            });
          fieldsInitial.push(initialRowData);
        } else {
          fieldsInitial = values[x.props.name];
        }
      }

      x.component === SimpleFormFieldArray
        ? (initialValues[x.props.name] = fieldsInitial)
        : x.fields &&
          x.fields.forEach((y: IFormField) => {
            if (y.component !== Label && y.component !== Header) {
              initialValues[y.props.name] = getValueOrDefault(
                values && values[y.props.name],
                y.props.defaultValue
              );
            }
          });
    } else {
      if (
        x.component !== Label &&
        x.component !== Header &&
        values &&
        ![undefined, null].includes(values[x.props.name])
      ) {
        initialValues[x.props.name] = values[x.props.name];
      }
    }
  });
  return initialValues;
}

export class SimpleForm extends React.Component<ISimpleFormProps> {
  public render() {
    const schema: IFormField[] =
      this.props.formSchema.filter(notNullOrUndefined);

    const validationSchema: any = {};
    schema.forEach((x: IFormField) => {
      if (x.fields) {
        x.fields.map((y: IFormField) => {
          if (y.validation) {
            validationSchema[y.props.name] = y.validation;
          }
        });
      } else {
        validationSchema[x.props.name] = x.validation;
      }
    });

    const initialValues = getInitialValues(schema, this.props.intialValues);

    //console.log(initialValues)
    return (
      <div className={this.props.className}>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationSchema)}
          onSubmit={(values, actions) => {
            Promise.resolve(this.props.onSubmit(values, actions)).then((v) => {
              actions.setSubmitting(false);
              actions.setTouched({});
              if (
                this.props.resetOnSubmit === true ||
                this.props.resetOnSubmit === undefined
              )
                actions.resetForm(values);
            });
          }}
          validateOnBlur={true}
          enableReinitialize={true}
          render={({
            isValidating,
            isSubmitting,
            submitForm,
            dirty,
            values,
          }) => {
            return (
              <SemanticForm
                novalidate
                as="div"
                loading={this.props.loading}
                style={{ position: "initial" }}
              >
                {schema.map((x: IFormField, indexX: number) => {
                  if (x.fields) {
                    const Container = x.component;
                    if (Container === SimpleFormFieldArray) {
                      const props = { ...this.props, ...x.props };
                      return (
                        <Row key={indexX}>
                          {renderFormField(
                            x.component,
                            this.props.disabled,
                            this.props.readonly || x.props.readonly,
                            props,
                            x.fields,
                            x.fieldArrayFields
                          )}
                        </Row>
                      );
                    }

                    return (
                      <>
                        <Row>
                          <Col>
                            <Segment style={{ paddingBottom: 0 }} basic>
                              <Header size="small">{x.label}</Header>
                            </Segment>
                          </Col>
                        </Row>
                        <Container {...x.props}>
                          <Row>
                            {x.fields &&
                              x.fields.map((y: IFormField, indexY: number) => (
                                <Col key={indexY} {...y.colSize}>
                                  {renderFormField(
                                    y.component || FormField,
                                    this.props.disabled,
                                    this.props.readonly || y.props.readonly,
                                    { ...y.props, key: indexY }
                                  )}
                                </Col>
                              ))}
                          </Row>
                        </Container>
                      </>
                    );
                  } else {
                    return renderFormField(
                      x.component || FormField,
                      this.props.disabled,
                      this.props.readonly || x.props.readonly,
                      {
                        ...x.props,
                        key: indexX,
                      }
                    );
                  }
                })}
                <Segment
                  basic
                  style={{
                    padding: 0,
                    margin: "10px 0px",
                    position: "initial",
                  }}
                >
                  {this.props.renderSubmitButtons ? (
                    this.props.renderSubmitButtons({
                      disabled: isSubmitting || isValidating || !dirty,
                      isValidating,
                      isSubmitting,
                      submitForm,
                      dirty,
                      values,
                    })
                  ) : (
                    <Button
                      secondary
                      onClick={submitForm}
                      fluid
                      disabled={isSubmitting || isValidating || !dirty}
                    >
                      {this.props.submitText || "Submit"}
                    </Button>
                  )}
                </Segment>
                <Prompt
                  when={!this.props.loading && dirty && !isSubmitting}
                  message="You have unsaved changes!"
                />
              </SemanticForm>
            );
          }}
        />
      </div>
    );
  }
}

export const renderFormField = (
  component: any,
  disabled: boolean | undefined,
  readonly: boolean | undefined,
  props: IFormFieldProps,
  fields?: IFormField[],
  fieldArrayFields?: IFormField[],
  value?: any
): React.ReactNode => {
  return (
    <Field key={props.key} name={props.name}>
      {(form: FieldProps<any>) => {
        props["onChange"] = readonly ? noop : form.form.handleChange;
        props["disabled"] = props["disabled"] || disabled;
        props["value"] = form.form.values[props.name] || value;
        props["setFieldValue"] = readonly ? noop : form.form.setFieldValue;
        props["error"] =
          form.form.errors[props.name] && form.form.touched[props.name]
            ? form.form.errors[props.name]!.toString()
            : undefined;
        props["fields"] = fields;
        props["fieldArrayFields"] = fieldArrayFields;
        if (readonly || (props.prefill && !props.prefillonce)) {
          props["readOnly"] = true;
        }
        return <MemoField component={component} {...props} />;
      }}
    </Field>
  );
};
