// When the editor changes, you can get notified via the

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";
import { $getRoot, EditorState } from "lexical";
import { useEffect, useRef } from "react";

// OnChangePlugin!
export function OnChangePlugin({
  onChange,
}: {
  onChange: (state: EditorState, html: string | null) => void;
}) {
  // Access the editor through the LexicalComposerContext
  const [editor] = useLexicalComposerContext();
  // Wrap our listener in useEffect to handle the teardown and avoid stale references.
  const first = useRef(true);
  useEffect(() => {
    // most listeners return a teardown function that can be called to clean them up.
      return editor.registerUpdateListener(({ editorState }) => {
      // call onChange here to pass the latest state up to the parent.
      editor.update(() => {
      if (first.current) {
          first.current = false;
        } else {
          const root = $getRoot();
          const isEmpty =
            root.getFirstChild()?.isEmpty() && root.getChildrenSize() === 1;
          onChange(
            editorState,
            isEmpty ? null : $generateHtmlFromNodes(editor)
          );
        }
      });
    });
  }, [editor, onChange]);
  return null;
}
