import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function usePageLeave(onPageLeave: () => void) {
  let location = useLocation();
  let history = useHistory();
  React.useEffect(() => {
    const stopListen = history.listen(newLocation => {
      if (location.pathname != newLocation.pathname) {
        onPageLeave();
      }
    });
    return stopListen;
  });
}
