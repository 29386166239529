import * as React from "react";
import { Link, useLocation, RouteProps } from "react-router-dom";
import { Menu, MenuItemProps, Icon, SemanticICONS } from "semantic-ui-react";
import { routes, IMenuItem, defaultMenu, flatMenu } from "../routes";
import useToggle from "../hooks/useToggle";
import usePageLeave from "../hooks/usePageLeave";

interface INavigationContextProps {
  children: React.ReactNode;
}

interface NavigationContextState {
  routes: IMenuItem[];
  currentMenu: string;
  menu: IMenuItem[] | null;
  menuVisible: boolean;
  toggleMenu: () => void;
}

export const NavigationCtx = React.createContext<NavigationContextState>({
  routes: [],
  currentMenu: "",
  menu: null,
  menuVisible: false,
  toggleMenu: () => {},
});

export const NavigationConsumer = NavigationCtx.Consumer;

export const renderMenu = (
  menu: IMenuItem[],
  currentPath?: string,
  checkRight?: (right: string) => boolean,
  preload?: (item: IMenuItem) => void
) => {
  let items = menu;
  if (checkRight) {
    items = items.filter((r) =>
      r.accessRight ? r.accessRight.some((right) => checkRight(right)) : true
    );
  }
  return (
    <>
      {items.map((m) =>
        m.menu || m.submenu ? (
          <Menu.Item
            key={m.id || m.name}
            active={currentPath ? isActive(m, currentPath) : false}
            {...(m.menu
              ? {
                  icon: m.icon,
                  to: m.path,
                  onMouseEnter: () => preload && preload(m),
                  name: m.name,
                }
              : {})}
            {...(m.path ? { as: Link } : {})}
          >
            {m.submenu && (
              <>
                {m.menu && (
                  <>
                    <Icon name={m.icon} />
                    <Menu.Header>{m.name}</Menu.Header>
                  </>
                )}
                <Menu.Menu>
                  {renderMenu(m.submenu, currentPath, checkRight, preload)}
                </Menu.Menu>
              </>
            )}
          </Menu.Item>
        ) : null
      )}
    </>
  );
};

function isActive(r: IMenuItem, pathname: string): boolean {
  return (
    !!(r.path && r.path != "/" && pathname.startsWith(r.path!)) ||
    !!(r.submenu && r.submenu.some((s) => isActive(s, pathname)))
  );
}

export const NavigationContext: React.FC<INavigationContextProps> = (props) => {
  let location = useLocation();
  const [menuVisible, toggleMenu, _, disable] = useToggle(false);

  usePageLeave(() => {
    disable();
  });

  let currentMenu =
    routes.find((r) => isActive(r, location.pathname)) || defaultMenu;

  return (
    <NavigationCtx.Provider
      value={{
        currentMenu: currentMenu.id!,
        routes: routes.map((r) => ({
          ...r,
          active: r == currentMenu,
        })),
        menu: routes.find((r) => r.id == currentMenu.id)!.submenu || null,
        menuVisible,
        toggleMenu,
      }}
    >
      {props.children}
    </NavigationCtx.Provider>
  );
};
