/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * Phone
 *
 * Mobile
 *
 * FAX
 *
 * SIP
 */
export enum EPhoneType {
    Phone = 'Phone',
    Mobile = 'Mobile',
    FAX = 'FAX',
    SIP = 'SIP',
}
