import {
  CancelledError,
  MutationCache,
  QueryCache,
  QueryClient,
} from "@tanstack/react-query";
import { ApiError, OpenAPI } from "./generated";
import { getHeadersWithToken } from "./api/getHeadersWithToken";
import { ApiRequestOptions } from "./generated/core/ApiRequestOptions";
import { EmitErrorEvent, EmitWarningEvent } from "./api/EmitWarningEvent";

const getHeaders = async (options: ApiRequestOptions) => {
  const headers = await getHeadersWithToken(options);
  return headers as any;
};
OpenAPI.HEADERS = getHeaders;

const onError = (error: any) => {
  if (error instanceof CancelledError) return;
  //let body = (error as ApiError).body;
  const e = error as ApiError;
  try {
    if (typeof e.body == "object") {
      const error = e.body;
      if (error.status == 424) {
        EmitWarningEvent(`${error.detail}`);
      } else {
        EmitErrorEvent(`${e.status ? e.status : ""} ${error.title}`);
      }
    } else if (typeof e.body == "string") {
      EmitErrorEvent(`${error.body}`);
    }
  } catch (er) {
    EmitErrorEvent(`Server error: ${e.body}`);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 500,
    },
  },
  queryCache: new QueryCache({
    onError: onError,
  }),
  mutationCache: new MutationCache({
    onError: onError,
  }),
});
