import * as React from "react";
import { Segment, Header, Button, FormInput } from "semantic-ui-react";
import SimpleForm, {
  IFormField,
  SimpleFormCheckbox,
} from "../../components/SimpleForm";
import * as Yup from "yup";
import UserApi from "../../api/UserApi";
import { PasswordField } from "../Register/components/PasswordField";
import { useQuery, useMutation } from "@tanstack/react-query";
import { ChangePasswordModel } from "../../generated/models/ChangePasswordModel";
import useMessages from "../../hooks/useMessages";
import { SideOverlay } from "../../components/SideOverlay";
import { CancelButton } from "../../components/CancelButton";
import cancelForm from "../../components/CancelFunction";

interface IChangePasswordProps {
  edit: boolean;
  closeEdit: (edit: boolean) => void;
}

interface IChangePasswordDefault {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePassword: React.FC<IChangePasswordProps> = (props) => {
  let initModel: IChangePasswordDefault = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const [defaultPassword, setDefaultPassword] =
    React.useState<IChangePasswordDefault>(initModel);
  const message = useMessages();
  const { mutateAsync: changePassword } = useMutation(
    async (v: ChangePasswordModel) => {
      const result = await UserApi.changePasswordPost({
        currentPassword: v.currentPassword,
        newPassword: v.newPassword,
      });
      setDefaultPassword(initModel);
      props.closeEdit(false);
      message.addMessage(
        { header: `Password changed successful!` },
        "success",
        true
      );
      return result;
    }
  );

  const pwSchema = Yup.string()
    .required("Password is required")
    .notOneOf(
      [Yup.ref("currentPassword"), ""],
      "Password must not match with current password"
    )
    .min(8, "Password must be at least 8 characters long")
    .max(64, "Password is too long")
    .matches(/[0-9]/, "Password must include at least 1 digit")
    .matches(
      /[a-z]/,
      "Password must include at least 1 lower case and one upper case character"
    )
    .matches(
      /[A-Z]/,
      "Password must include at least 1 lower case and one upper case character"
    )
    .matches(
      /[?!#@$%^&*)(+=._-]/,
      "Password must include at least 1 special character"
    )
    .trim();
  const schema: IFormField[] = [
    {
      component: "div",
      props: {},
      fields: [
        {
          validation: Yup.string().required("Please Confirm old password"),
          props: {
            name: "currentPassword",
            label: "Current Password",
            type: "password",
          },
          colSize: {
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12,
          },
        },
        {
          validation: pwSchema,
          component: PasswordField,
          props: {
            name: "newPassword",
            label: "New Password",
            type: "password",
            autoComplete: "new-password",
            schema: pwSchema,
            information: (
              <>
                <p>
                  <strong>Strong passwords</strong> help prevent unauthorized
                  people from accessing your data and should be difficult to
                  guess or crack.
                </p>
                <p>
                  <strong>A good password:</strong>
                  <ul>
                    <li>Is at least eight characters long</li>
                    <li>
                      Doesn't contain your user name, real name, or company name
                    </li>
                    <li>Doesn't contain a complete word</li>
                    <li>Is significantly different from previous passwords</li>
                    <li>
                      Contains uppercase letters, lowercase letters, numbers,
                      and symbols
                    </li>
                  </ul>
                </p>
              </>
            ),
          },
          colSize: {
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12,
          },
        },
        {
          validation: Yup.string()
            .required("Please Confirm password")
            .oneOf([Yup.ref("newPassword"), ""], "Passwords must match"),
          props: {
            name: "confirmNewPassword",
            label: "Confirm Password",
            type: "password",
            autoComplete: "new-password",
          },
          colSize: {
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12,
          },
        },
      ],
    },
  ];
  return (
    <>
      <SideOverlay
        visible={!!props.edit}
        onHide={() => {
          props.closeEdit(false);
        }}
        dimmed
        className="edit-overlay"
        render={() => (
          <Segment basic style={{ minHeight: "50%" }}>
            <Header>Change Password</Header>
            <SimpleForm
              formSchema={schema}
              intialValues={defaultPassword}
              onSubmit={(v) => {
                {
                  setDefaultPassword({
                    currentPassword: v.currentPassword,
                    newPassword: v.newPassword,
                    confirmNewPassword: v.confirmNewPassword,
                  });
                  changePassword({
                    currentPassword: v.currentPassword,
                    newPassword: v.newPassword,
                  });
                }
              }}
              renderSubmitButtons={({
                submitForm,
                values,
                dirty,
                isSubmitting,
              }) => (
                <>
                  <Button
                    icon="key"
                    size="small"
                    content="change password"
                    onClick={submitForm}
                    secondary
                  />
                  <CancelButton
                    dirty={dirty}
                    isSubmitting={isSubmitting}
                    onCancelClick={(v) =>
                      props.closeEdit(
                        cancelForm(dirty, isSubmitting, defaultPassword, true)
                      )
                    }
                  />
                </>
              )}
            />
          </Segment>
        )}
      ></SideOverlay>
    </>
  );
};
