/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Status of logged-in user
 *
 * OK
 *
 * TermsMissing
 *
 * TermsUpdated
 */
export enum ELoginStatus {
    OK = 'OK',
    TermsMissing = 'TermsMissing',
    TermsUpdated = 'TermsUpdated',
}
