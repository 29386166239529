import * as React from "react";
import useMessages from "../hooks/useMessages";
import useUser from "../hooks/UseUser";
import useEventListener from "../hooks/useEventListener";
import { FETCH_API_ERROR, FETCH_API_WARNING } from "../api/EmitWarningEvent";

interface ICatchApiErrorsProps {
  children: JSX.Element;
}

export const CatchApiErrors = (props: ICatchApiErrorsProps) => {
  const msg = useMessages();
  const userCtx = useUser();
  useEventListener(
    FETCH_API_ERROR,
    (e: any) => userCtx.user && msg.addMessage({ header: e.detail }, "error")
  );
  useEventListener(
    FETCH_API_WARNING,
    (e: any) => userCtx.user && msg.addMessage({ header: e.detail }, "warning")
  );
  return props.children;
};
