import * as React from "react";
import { useParams } from "react-router-dom";
import { EEntityType, SqlQueryService } from "../../generated";
import ProgrammeHeader from "./ProgrammeHeader";
import { useQuery } from '@tanstack/react-query';
import { SQLQueryTable } from "../Admin/SQLQueryTable";
import RightsApi from "../../api/RightsApi";

interface IEmergencyContactsResult { }

export const EmergencyContacts: React.FC<IEmergencyContactsResult> = (
  props: IEmergencyContactsResult
) => {
  let { id, upn } = useParams<{ upn?: string; id?: string }>();
  const Programme = useQuery(["getProgrammeGET", id], () =>
    SqlQueryService.getEmergencyContactsPost(id)
  );
  const Rights = useQuery(["getRightsGET", id], () =>
    RightsApi.getRightsGet(EEntityType.Programme, id)
  );


  const queryResult = Programme.data;
  return (
    <div>
      <ProgrammeHeader
        referenceNo={id}
        upn={upn}
        rights={Rights.data}
        loading={Programme.isLoading}
      />
      <SQLQueryTable
        columns={queryResult && queryResult.columns}
        exportPostfix={"EmergencyContacts"}
        values={queryResult && queryResult.values}
        loading={Programme.isLoading}
      />
    </div>
  );
};

export default EmergencyContacts;