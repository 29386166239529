import _ from "lodash";
import * as React from "react";
import useSearchContext from "../hooks/UseSearchContext";
import { useHistory } from "react-router-dom";
import { EInfoURLSourceLocation } from "../generated";
import SearchInput from "./SearchInput";

interface MenuSearchProps {
  overlayOpen?: boolean;
}

export const MenuSearch: React.FC<MenuSearchProps> = ({ overlayOpen }) => {
  let { loading, open, openResultPage, search, result, query } =
    useSearchContext();

  let history = useHistory();

  const select = (
    e: any,
    data: {
      result: {
        url?: string | null;
        title: string;
        sourceLocation?: EInfoURLSourceLocation;
      };
    }
  ) => {
    search(data.result.title);
    if (
      data.result.sourceLocation &&
      data.result.sourceLocation !== EInfoURLSourceLocation.WebApp
    ) {
      // TODO check enum != webapp
      window.open(window.location.origin + data.result.url);
    } else {
      data.result.url && history.push(data.result.url);
    }
  };
  return (
    <SearchInput
      category
      loading={loading}
      onResultSelect={select}
      onSearch={search}
      onEnter={openResultPage}
      open={overlayOpen != undefined ? overlayOpen : open}
      results={result}
      value={query ?? undefined}
    />
  );
};

export default MenuSearch;
