import * as React from "react";
import { LoginModel } from "../generated";
import UserApi from "../api/UserApi";
import { authenticationParameters, checkActiveAccount } from "../authProvider";
import { useQuery } from "@tanstack/react-query";
import { appInsights } from "../index";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import useEventListener from "../hooks/useEventListener";
import { FETCH_API_ERROR } from "../api/EmitWarningEvent";

interface IUserContextProps {
  children: React.ReactNode;
}

export interface UserContextState {
  loading: boolean;
  user: LoginModel | null;
  login: (loginType?: "popup" | "redirect") => void;
  reload: () => void;
  logout: (loginType?: "popup" | "redirect") => void;
}

export const UserCtx = React.createContext<UserContextState>({
  loading: true,
  user: null,
  reload: () => {},
  login: () => {},
  logout: () => {},
});

function generateHash(string: string) {
  var hash = 0;
  if (string.length == 0) return hash.toString();
  for (let i = 0; i < string.length; i++) {
    var charCode = string.charCodeAt(i);
    hash = (hash << 7) - hash + charCode;
    hash = hash & hash;
  }
  return hash.toString();
}

export const UserConsumer = UserCtx.Consumer;

export const UserContext: React.FC<IUserContextProps> = (props) => {
  const { instance } = useMsal();

  const getUser = useQuery(["getCurrentUserGET"], async () => {
    const user = await UserApi.getCurrentUserGet();
    if (user && user.loginID) {
      appInsights.setAuthenticatedUserContext(generateHash(user.loginID));
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
    return user;
  });

  useEventListener(FETCH_API_ERROR, (e: any) => {
    // API Error, check if still have a user
    checkActiveAccount(getUser.data);
  });
  useEffect(() => {
    checkActiveAccount(getUser.data);
  }, [getUser.data]);

  const handleLogin = React.useCallback(
    async (loginType: "popup" | "redirect" = "redirect") => {
      if (loginType === "popup") {
        await instance.loginPopup(authenticationParameters).catch((e) => {
          console.log(e);
          if (
            e.name == "BrowserAuthError" ||
            e.errorCode === "popup_window_error" ||
            e.errorCode === "empty_window_error"
          ) {
            // Pop up blocked?
            handleLogin("redirect");
          }
        });
      } else if (loginType === "redirect") {
        await instance.loginRedirect(authenticationParameters).catch((e) => {
          console.log(e);
        });
      }
      checkActiveAccount(getUser.data);
    },
    [getUser.data]
  );
  const handleLogout = React.useCallback(
    async (loginType: "popup" | "redirect" = "redirect") => {
      if (loginType === "popup") {
        await instance.logoutPopup(authenticationParameters).catch((e) => {
          console.log(e);
          if (
            e.name == "BrowserAuthError" ||
            e.errorCode === "popup_window_error" ||
            e.errorCode === "empty_window_error"
          ) {
            // Pop up blocked?
            handleLogout("redirect");
          }
        });
      } else if (loginType === "redirect") {
        await instance.logoutRedirect(authenticationParameters).catch((e) => {
          console.log(e);
        });
      }
      checkActiveAccount(getUser.data);
    },
    [getUser.data]
  );
  /*  console.log("UserContext", getUser.data)*/
  return (
    <UserCtx.Provider
      value={{
        loading: getUser.isLoading,
        logout: handleLogout,
        login: handleLogin,
        reload: () => getUser.refetch(),
        user: getUser.data || null,
      }}
    >
      {props.children}
    </UserCtx.Provider>
  );
};
