/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * Delegation
 *
 * Individual
 */
export enum EInvitationType {
    Delegation = 'Delegation',
    Individual = 'Individual',
}
