import { createMedia } from "@artsy/fresnel";

export const AppMedia = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 768,
  },
});
export const mediaStyles = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;
