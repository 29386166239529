import * as React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { NavMenu } from "../../components/NavMenu";
import { Row, Col } from "react-flexbox-grid";
import { SideMenu } from "../../components/SideMenu";
import { Footer } from "../../components/Footer";
import useUser from "../../hooks/UseUser";
import { LoadingScreen } from "../LoadingScreen";
import useRights from "../../hooks/UseRights";
import { IMenuItem } from "../../routes";
import { Container, Segment } from "semantic-ui-react";
import "../../styles/Global.css";
import "../../styles/print.css";
import useMessages from "../../hooks/useMessages";
import { ELoginStatus } from "../../generated";
import { MainContent } from "./MainContent";
import { Media } from "../../AppMedia";

interface UserLayoutProps {
  children?: React.ReactNode;
  loading: boolean;
  allowed: boolean;
  loggedIn: boolean;
  path: string;
  name: string;
}

export const UserLayout = ({
  loading,
  allowed,
  children,
  path,
  name,
  loggedIn,
}: UserLayoutProps) => {
  const msg = useMessages();
  React.useEffect(() => {
    if (loggedIn && !loading && !allowed) {
      msg.addMessage(
        { header: `You are not allowed to access ${name}` },
        "error"
      );
    }
    return () => {};
  }, [path, loading, loggedIn, allowed]);
  return (
    <div>
      {loading ? (
        <LoadingScreen />
      ) : allowed ? (
        <div className="layout-main">
          <NavMenu />
          <Container className="m-0" fluid>
            <Row className="layout-content">
              <Media greaterThanOrEqual="computer">
                {(className, renderChildren) =>
                  renderChildren && (
                    <>
                      <Col md={2} className="p-0 hide-print">
                        <SideMenu />
                      </Col>
                      <Col md={10}>
                        <MainContent title={name}>{children}</MainContent>
                      </Col>
                    </>
                  )
                }
              </Media>
              <Media at="mobile">
                {(className, renderChildren) =>
                  renderChildren && (
                    <Col md>
                      <MainContent title={name}>{children}</MainContent>
                    </Col>
                  )
                }
              </Media>
            </Row>
          </Container>
        </div>
      ) : (
        <Redirect to="/" />
      )}
      <Media greaterThanOrEqual="computer">
        <Footer />
      </Media>
      <Media at="mobile">
        <div style={{ height: "2em" }} />
      </Media>
    </div>
  );
};

export const UserLayoutRoute = ({
  component,
  render,
  path,
  urlParam,
  ...rest
}: IMenuItem) => {
  const consentPath = "/profile/consent";
  const userCtx = useUser();
  const rights = useRights();
  const history = useHistory();
  const RouteComp: any = component;
  const allowed =
    !rest.accessRight || rest.accessRight.some((r) => rights.hasRight(r));

  if (
    history.location.pathname != consentPath &&
    userCtx.user &&
    userCtx.user.status &&
    (userCtx.user.status == ELoginStatus.TermsMissing ||
      userCtx.user.status == ELoginStatus.TermsUpdated)
  ) {
    // if the user needs to consent, force to consent page!
    return <Redirect to={consentPath} />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(matchProps) => (
        <UserLayout
          name={rest.name}
          path={path as string}
          allowed={!!userCtx.user && allowed}
          loggedIn={!!userCtx.user}
          loading={userCtx.loading || rights.loading}
        >
          {render ? render(matchProps) : <RouteComp {...matchProps} />}
        </UserLayout>
      )}
    />
  );
};
