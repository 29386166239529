import * as React from "react";

export default function useToggle(
  initial: boolean
): [boolean, () => void, () => void, () => void] {
  const [active, setActive] = React.useState(initial);

  function toggle() {
    setActive(!active);
  }
  function enable() {
    setActive(true);
  }
  function disable() {
    setActive(false);
  }
  return [active, toggle, enable, disable];
}
