import * as React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Redirect, Route } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { NavMenu } from "../../components/NavMenu";
import useUser from "../../hooks/UseUser";
import { LoadingScreen } from "../LoadingScreen";
import { MainContent } from "./MainContent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import settings from "../../settings.json";

export const LandingLayout = ({ children, name }: any) => (
  <div>
    <div className="layout-main">
      <NavMenu anonymous />
      <Row className="layout-content">
        <Col md>
          <MainContent title={name}>{children}</MainContent>
        </Col>
      </Row>
    </div>
    <Footer />
  </div>
);

export const LandingLayoutRoute = ({ component: Component, ...rest }: any) => {
  const userCtx = useUser();
  return (
    <Route
      {...rest}
      render={(matchProps) =>
        userCtx.loading ? (
          <LoadingScreen />
        ) : userCtx.user ? (
          <Redirect to="/profile" />
        ) : (
          <LandingLayout name={rest.name}>
            {rest.path === "/register" ? (
              <GoogleReCaptchaProvider
                reCaptchaKey={settings.reCaptchaKey}
                language="en"
              >
                <Component {...matchProps} />
              </GoogleReCaptchaProvider>
            ) : (
              <Component {...matchProps} />
            )}
          </LandingLayout>
        )
      }
    />
  );
};
