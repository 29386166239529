import * as React from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  Row,
  FilterProps,
  ColumnInstance,
  FilterTypes,
  UseFiltersColumnOptions,
  ColumnWithLooseAccessor,
} from "react-table";
import {
  Table,
  Pagination,
  Input,
  Segment,
  Button,
  Confirm,
} from "semantic-ui-react";
import {
  saveSpreadsheetCSV,
  ISpreadSheetData,
  CellDataType,
} from "../../utils/SpreadsheetExport";
import { Media } from "../../AppMedia";
import "../../styles/Datatables.css";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, ...col },
  readOnlyFilter,
}: FilterProps<any>) {
  const count = preFilteredRows.length;
  return (
    <Input
      value={filterValue || ""}
      icon={!filterValue && "search"}
      fluid
      disabled={readOnlyFilter}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${col.Header}`}
      className={filterValue && "with-value"}
      title={filterValue}
      focus={filterValue}
    />
  );
}

const getTableData = (
  rows: Row<any>[],
  columns: (ColumnInstance<any> & {
    exportAccessor?: (value: any) => string;
  } & {
    noExport?: (value: any) => string;
  } & { fixedNumber?: (value: any) => string })[]
): ISpreadSheetData | null => {
  const usedColumns = columns.filter((f) => f.noExport === undefined);
  const dataColumns = usedColumns.map((c) => ({
    header: c.Header as string,
    type: /*c.exportType ||*/ c.fixedNumber
      ? CellDataType.NUMBER
      : CellDataType.ANY,
  }));
  const dataRows = rows.map((row) => {
    let dataRow: string[] = [];
    usedColumns.forEach((c) => {
      let value = row.values[c.id];
      if (c.exportAccessor) {
        value = c.exportAccessor(row.original);
      }
      if (typeof value == "string") {
        if (value == "-") {
          value = 0;
        } else {
          value = value.replace("�", "-");
        }
        dataRow.push(value);
      } else if (typeof value == "number") {
        if (c.fixedNumber) {
          value = value.toFixed(c.fixedNumber as unknown as number);
          value = value.replace(".", ",");
        } else if (value == 0) {
          value = c.fixedNumber ? "0,00" : "0";
        } else {
          value = value.toString();
        }
        dataRow.push(value);
      } else {
        if (c.fixedNumber) {
          value = 0.0;
          value = value.toFixed(c.fixedNumber as unknown as number);
          value = value.replace(".", ",");
          dataRow.push(value);
        } else {
          dataRow.push(value ? value.toString() : c.fixedNumber ? "0,00" : "");
        }
      }
    });
    return dataRow;
  });
  return {
    columns: dataColumns,
    rows: dataRows,
  };
};

export type DataTableColumn<TData extends {}> = {
  exportAccessor?: (value: TData) => string;
  noExport?: string | null;
  fixedNumber?: string | null;
  className?: string;
  style?: React.CSSProperties;
} & ColumnWithLooseAccessor<TData> &
  UseFiltersColumnOptions<TData>;

export interface IDataTableProps<TData extends {}> {
  columns: DataTableColumn<TData>[];
  data: TData[];
  loading?: boolean;
  scrollHorizontal?: boolean;
  exportFileName?: string | null;
  header?: React.ReactNode;
  renderRowCard?: (row: Row<TData>) => React.ReactNode;
  paging?: number;
  buttonStyle?: { padding: string };
  autoResetFilters?: boolean;
  autoResetPage?: boolean;
  readOnlyFilter?: boolean;
  downloadButtonBottom?: boolean;
  downloadDialog?: string;
}

export function DataTable<TData extends {}>(props: IDataTableProps<TData>) {
  const filterTypes: FilterTypes<TData> = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id as any];
          return rowValue
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    (): UseFiltersColumnOptions<TData> => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    preSortedRows,
    columns,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<TData>(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn: defaultColumn as any,
      filterTypes,
      autoResetFilters: props.autoResetFilters,
      autoResetPage: props.autoResetPage,
      readOnlyFilter: props.readOnlyFilter,
      downloadButtonBottom: props.downloadButtonBottom,
      downloadDialog: props.downloadDialog,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const [Open, SetOpen] = React.useState(false);

  const exportCSV = React.useCallback(() => {
    const data = getTableData(preSortedRows, columns);
    data &&
      props.exportFileName &&
      saveSpreadsheetCSV(props.exportFileName, data);
  }, [preSortedRows, columns]);

  const firstPageRows = page;
  const results = props.data.length; // to show count of all results found
  if (props.paging && pageSize != props.paging && props.paging >= 1) {
    if (props.paging === 1000 && pageSize !== results) {
      setPageSize(results);
    } else {
      setPageSize(props.paging);
    }
  }

  const count = pageCount * pageSize;
  const scrollStyle: React.CSSProperties = { overflowX: "scroll" };
  return (
    <>
      {!props.downloadButtonBottom && (
        <div
          style={{ display: "inline-block", width: "100%", marginTop: "5px" }}
        >
          {props.header}
          {props.exportFileName && (
            <Button
              className="datatableButtons"
              secondary
              style={props.buttonStyle}
              floated="right"
              content="Download"
              icon="cloud download"
              onClick={() =>
                props.downloadDialog != undefined ? SetOpen(true) : exportCSV()
              }
            />
          )}
        </div>
      )}
      <div style={props.scrollHorizontal ? scrollStyle : undefined}>
        <Table
          className="data-table"
          sortable
          celled
          fixed={!props.scrollHorizontal}
          {...getTableProps()}
        >
          <Table.Header>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.getHeaderGroupProps().key}>
                <Media greaterThanOrEqual="computer">
                  {(className, renderChildren) =>
                    renderChildren && (
                      <Table.Row {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <Table.HeaderCell
                            sorted={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "descending"
                                  : "ascending"
                                : undefined
                            }
                            {...column.getHeaderProps([
                              column.getSortByToggleProps(),
                              {
                                className: (column as any).className,
                                style: (column as any).style,
                              } as any,
                            ])}
                          >
                            {column.render("Header")}
                          </Table.HeaderCell>
                        ))}
                      </Table.Row>
                    )
                  }
                </Media>
                {props.data &&
                  props.data.length > 0 &&
                  headerGroup.headers.some((c) => c.canFilter) && (
                    <Table.Row
                      {...headerGroup.getHeaderGroupProps()}
                      key={"header1"}
                    >
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <Table.HeaderCell
                          {...column.getHeaderProps([
                            {
                              className: (column as any).className,
                              style: (column as any).style,
                            } as any,
                          ])}
                        >
                          {column.canFilter ? column.render("Filter") : null}
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                  )}
              </React.Fragment>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {props.loading ? (
              <Table.Row>
                <Table.Cell colSpan={columns.length}>
                  <Segment style={{ margin: "2em" }} basic loading></Segment>
                </Table.Cell>
              </Table.Row>
            ) : (
              firstPageRows.map((row, i) => {
                prepareRow(row);
                return (
                  <Table.Row {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Table.Cell
                          {...cell.getCellProps([
                            {
                              className: (cell as any).column.className,
                              style: (cell as any).column.style,
                            } as any,
                          ])}
                        >
                          {cell.render("Cell")}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
        {count > pageSize && (
          <>
            <div className="hide-print">
              <Pagination
                onPageChange={(e, { activePage }) =>
                  gotoPage(Number(activePage) - 1)
                }
                boundaryRange={0}
                ellipsisItem={null}
                siblingRange={1}
                activePage={pageIndex + 1}
                totalPages={pageCount}
              />
              <br />
              <br />
            </div>
            <div>
              Showing{" "}
              {pageIndex + 1 === pageCount ? results - pageSize : pageSize}{" "}
              results out of {results}
            </div>
          </>
        )}
      </div>
      <Confirm
        open={Open}
        header={"Beware before downloading!"}
        content={`${props.downloadDialog}`}
        onCancel={() => SetOpen(false)}
        onConfirm={() => {
          SetOpen(false);
          exportCSV();
        }}
      />
      {props.downloadButtonBottom && (
        <div style={{ paddingTop: "10px" }}>
          {props.header}
          {props.exportFileName && (
            <Button
              className="mb-5 datatableButtons"
              secondary
              style={props.buttonStyle}
              floated="right"
              content="Download"
              icon="cloud download"
              onClick={() =>
                props.downloadDialog != undefined ? SetOpen(true) : exportCSV()
              }
            />
          )}
        </div>
      )}
    </>
  );
}
