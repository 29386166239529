/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * Unknown
 *
 * DisableRole
 *
 * Parent
 *
 * Leader
 *
 * HideRole
 */
export enum EGuardianTypes {
    Unknown = 'Unknown',
    DisableRole = 'DisableRole',
    Parent = 'Parent',
    Leader = 'Leader',
    HideRole = 'HideRole',
}
