// import { SearchApi, Configuration } from "../generated";
// import { fetchApi, fetchApiFactory } from "./ApiErrorFetch";

import { SearchService } from "../generated";

// export const GetSearchApi = (signal?: AbortSignal) => new SearchApi(
//   new Configuration({ basePath: window.location.origin, fetchApi: fetchApiFactory(signal) })
// );

// const Search = GetSearchApi();

export default SearchService;
