/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Types of Entities
 *
 * WebApp
 *
 * User
 *
 * Resource
 *
 * Form
 *
 * Directory
 *
 * Region
 *
 * National
 *
 * Chapter
 *
 * Programme
 *
 * Invitation
 */
export enum EEntityType {
    WebApp = 'WebApp',
    User = 'User',
    Resource = 'Resource',
    Form = 'Form',
    Directory = 'Directory',
    Region = 'Region',
    National = 'National',
    Chapter = 'Chapter',
    Programme = 'Programme',
    Invitation = 'Invitation',
}
