import * as React from "react";
import { createRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import App from "./App";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
//import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
//import registerServiceWorker from "./registerServiceWorker";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const browserHistory = createBrowserHistory({ basename: baseUrl || "" });
const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    disableTelemetry: process.env.NODE_ENV == "development",
    connectionString:"InstrumentationKey=efc14064-0700-4463-8282-4c12bcf91f21;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

const root = createRoot(rootElement!);
root.render(
  <Router history={browserHistory}>
    <App />
  </Router>
);

//registerServiceWorker();
