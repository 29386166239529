import { StrictMessageProps } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";

export default function useMessages() {
  const { addToast } = useToasts();
  function addMessage(
    message: StrictMessageProps,
    appearance: "success" | "error" | "warning" | "info" = "info",
    autoClose = false,
    pauseOnHover = true
  ) {
    addToast(message, {
      appearance: appearance,
      autoDismiss: autoClose,
      pauseOnHover: pauseOnHover,
    });
  }
  return { addMessage };
}
