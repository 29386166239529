import * as React from "react";
import { flatRoutes } from "../routes";
import { LoadableComponent } from "@loadable/component";

/**
 * preload routes that match the given route filter
 * @param filterPath
 */
export function useRoutePreload(filterPath: (path: string) => boolean) {
  React.useEffect(() => {
    const preload = async () => {
      const preloadRoutes = flatRoutes.filter(o => o.path && o.component && (o.component as LoadableComponent<any>).preload && filterPath(o.path));
      preloadRoutes.forEach(o => (o.component as LoadableComponent<any>).preload());
    };
    preload();
  }, [])
}