// import { RightsApi, Configuration } from "../generated";
// import { fetchApi } from "./ApiErrorFetch";

import { RightsService } from "../generated";

// const Rights = new RightsApi(
//   new Configuration({
//     basePath: window.location.origin,
//     fetchApi: fetchApi
//   })
// );

export default RightsService;
