/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Rights_Programme {
    Rights = 'Programme.',
    Edit = 'Programme.Edit',
    View = 'Programme.View',
    EditChapter = 'Programme.EditChapter',
    IgnoreTimeframe = 'Programme.IgnoreTimeframe',
    ParticipationsRights = 'Programme.Participations.',
    ParticipationsView = 'Programme.Participations.View',
    ParticipationsEdit = 'Programme.Participations.Edit',
    ParticipantRights = 'Programme.Participant.',
    ParticipantView = 'Programme.Participant.View',
    ParticipantViewBase = 'Programme.Participant.ViewBase',
    ParticipantViewSensitive = 'Programme.Participant.ViewSensitive',
    ParticipantEdit = 'Programme.Participant.Edit',
    FormsRights = 'Programme.Forms.',
    FormsView = 'Programme.Forms.View',
    FormsEdit = 'Programme.Forms.Edit',
    InvitationRights = 'Programme.Invitation.',
    InvitationEdit = 'Programme.Invitation.Edit',
    InvitationParticipationsRights = 'Programme.Invitation.Participations.',
    InvitationParticipationsView = 'Programme.Invitation.Participations.View',
    InvitationParticipationsEdit = 'Programme.Invitation.Participations.Edit',
    InvitationParticipantRights = 'Programme.Invitation.Participant.',
    InvitationParticipantView = 'Programme.Invitation.Participant.View',
    InvitationParticipantViewBase = 'Programme.Invitation.Participant.ViewBase',
    InvitationParticipantViewSensitive = 'Programme.Invitation.Participant.ViewSensitive',
    InvitationParticipantEdit = 'Programme.Invitation.Participant.Edit',
}
