export const toDate = (date: string | Date) => {
  if (date instanceof Date) {
    return date;
  }
  return new Date(date);
};

export const formatISODate = (
  date?: string | Date | null,
  showTime?: boolean
) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }
  // yyyy-mm-dd from ISO
  return `${date?.substring(0, 10) ?? ""} ${
    (showTime && date?.substring(11, 16)) || ""
  }`.trim(); // ?? "";
};
