import _ from "lodash";
import * as React from "react";
import SearchApi from "../api/SearchApi";
import { useHistory } from "react-router-dom";
import { searchRoutes } from "../routes";
import useRights from "../hooks/UseRights";
import useResourcesSearch from "../hooks/useResourcesSearch";
import useMediaSearch from "../hooks/useMediaSearch";
import { useQuery } from "@tanstack/react-query";
import { cancelPromise } from "../utils/withAbortSignal";

interface SearchContextValue {
  result: MenuSearchCategories;
  loading: boolean;
  open?: boolean;
  query?: string | null;
  topicArea?: string | null;
  typeOfDoc?: string | null;
  keywords?: string | null;
  resourceYear?: string | null;
  setTopicArea: (topicarea: string) => void;
  setTypeOfDoc: (typeOfDoc: string) => void;
  setKeywords: (keywords: string) => void;
  setResourceYear: (year: string) => void;
  mediaTag?: string | null;
  mediaYear?: string | null;
  programmeType?: string | null;
  setMediaTag: (mediaTag: string) => void;
  setMediaYear: (mediaYear: string) => void;
  setProgrammeType: (programmeType: string) => void;
  filter: ISearchFilter;
  setFilter: (filter: ISearchFilter) => void;
  resetSearch: () => void;
  search: (query: string) => void;
  openResultPage: () => void;
  hits: number;
}

interface ISearchFilter {
  category:
    | "all"
    | "people"
    | "chapter"
    | "national"
    | "programme"
    | "resource"
    | "media";
  limit: number;
}

interface ISearchProgrammeInfo {
  displayName?: string | null;
  url?: string | null;
}

interface ISearchResult {
  title?: string | null;
  description?: string | null;
  id?: string | null;
  url?: string | null;
}

interface ISearchResultChapter extends ISearchResult {
  nationalName?: string | null;
  nationalID?: string | null;
  firstAirportCode?: string | null;
  secondAirportCode?: string | null;
  chapterName?: string | null;
}

interface ISearchResultNational extends ISearchResult {
  affilateCode?: string | null;
  region?: string | null;
  yearFounded?: string | null;
  email?: string | null;
  website?: string | null;
  nationalName?: string | null;
  countryID?: string | null;
}

interface ISearchResultProgramme extends ISearchResult {
  nationalID?: string | null;
  type?: string | null;
  minAge?: number;
  maxAge?: number;
  chapterName?: string | null;
  referenceNo?: string | null;
  preCamp1URL?: string | null;
  urls?: ISearchProgrammeInfo[] | null;
}

export interface ISearchResultResource extends ISearchResult {
  displayName?: string | null;
}

export interface ISearchResultMedia extends ISearchResult {
  displayName?: string | null;
}

interface ISearchResult_User extends ISearchResult {
  displayName?: string | null;
  chapterName?: string | null;
  nationalName?: string | null;
  nationalID?: string | null;
  azureLogin?: string | null;
}

interface SearchResultCategory {
  name: string;
  results: ISearchResult[];
}

interface SearchResultChapter {
  name: string;
  results: ISearchResultChapter[];
}

interface SearchResultNational {
  name: string;
  results: ISearchResultNational[];
}

interface SearchResultProgramme {
  name: string;
  results: ISearchResultProgramme[];
}

export interface SearchResultResource {
  name: string;
  results: ISearchResultResource[];
}

export interface SearchResultMedia {
  name: string;
  results: ISearchResultMedia[];
}

interface SearchResult_User {
  name: string;
  results: ISearchResult_User[];
}

interface SearchResultNavigation {
  name: string;
  results: ISearchResult[];
}

interface SearchContextProps {
  children: React.ReactNode;
}
interface SearchContextState extends MenuSearchCategories {
  value: string;
  search: boolean;
  entriesToTake?: number;
}

interface MenuSearchCategories {
  chapters?: SearchResultChapter;
  nationals?: SearchResultNational;
  peoples?: SearchResult_User;
  programmes?: SearchResultProgramme;
  resources?: SearchResultResource;
  medias?: SearchResultMedia;
  navigation?: SearchResultNavigation;
}

const initialState: ISearchFilter = {
  category: "all",
  limit: 10,
};

export const SearchCtx = React.createContext<SearchContextValue>({
  loading: true,
  open: undefined,
  result: {},
  query: "",
  topicArea: "",
  typeOfDoc: "",
  keywords: "",
  resourceYear: "",
  setResourceYear: () => {},
  setTopicArea: () => {},
  setTypeOfDoc: () => {},
  setKeywords: () => {},
  mediaTag: "",
  mediaYear: "",
  programmeType: "",
  setMediaTag: () => {},
  setMediaYear: () => {},
  setProgrammeType: () => {},
  filter: initialState,
  setFilter: () => {},
  search: () => {},
  resetSearch: () => {},
  openResultPage: () => {},
  hits: 0,
});

export const SearchConsumer = SearchCtx.Consumer;

export const SearchContext: React.FC<SearchContextProps> = (props) => {
  let history = useHistory();
  const rights = useRights();
  const [query, setQuery] = React.useState("");

  const [filter, setFilter] = React.useState<ISearchFilter>(initialState);
  const [open, setOpen] = React.useState<boolean | undefined>(undefined);
  let hits = 0;
  let results: MenuSearchCategories = {};

  const {
    resources,
    loading: resourcesLoading,
    setTopicArea,
    setTypeOfDoc,
    setKeywords,
    setResourceYear,
    topicArea,
    typeOfDoc,
    keywords,
    year,
  } = useResourcesSearch({
    queryString: query,
    entriesToTake: filter.limit,
  });

  const {
    media,
    loading: mediaLoading,
    setMediaTag,
    setMediaYear,
    setProgrammeType,
    mediaTag,
    mediaYear,
    programmeType,
  } = useMediaSearch({
    queryString: query,
    entriesToTake: filter.limit,
  });
  const resetSearch = () => {
    setFilter(initialState);
    setQuery("");
    setKeywords("");
    setTopicArea("");
    setTypeOfDoc("");
    setResourceYear("");
    setMediaTag("");
    setMediaYear("");
    setProgrammeType("");
    setOpen(undefined);
  };

  const searchChapter = useQuery(
    ["searchChapterGET", query, filter.limit],
    ({ signal }) =>
      cancelPromise(SearchApi.searchChapterGet(query, filter.limit), signal),
    { enabled: !!query }
  );

  const searchNational = useQuery(
    ["searchNationalGET", query, filter.limit],
    ({ signal }) =>
      cancelPromise(SearchApi.searchNationalGet(query, filter.limit), signal),
    { enabled: !!query }
  );

  const searchUser = useQuery(
    ["searchUserGET", query, filter.limit],
    ({ signal }) =>
      cancelPromise(SearchApi.searchUserGet(query, filter.limit), signal),
    { enabled: !!query }
  );

  const searchProgramme = useQuery(
    ["searchProgrammeGET", query, filter.limit],
    ({ signal }) =>
      cancelPromise(SearchApi.searchProgrammeGet(query, filter.limit), signal),
    { enabled: !!query }
  );

  const loading =
    searchChapter.isInitialLoading ||
    searchNational.isInitialLoading ||
    searchUser.isInitialLoading ||
    resourcesLoading ||
    mediaLoading ||
    searchProgramme.isInitialLoading;

  const navigations = searchRoutes(query, rights.hasRight);
  if (navigations.length && query.length) {
    results.navigation = {
      name: "App",
      results: navigations.map((r) => ({
        key: r.name,
        url: Array.isArray(r.path) ? r.path[0] : r.path,
        title: r.name,
        description: Array.isArray(r.path) ? r.path[0] : r.path,
      })),
    };
  }

  if (searchChapter.data && searchChapter.data.length) {
    results.chapters = {
      name: "Chapters",
      results: searchChapter.data.map((c) => ({
        key: c.id,
        url: c.url,
        title: c.title,
        description: c.national,
        id: c.id,
        nationalName: c.national,
        firstAirportCode: c.firstAirportCode,
        secondAirportCode: c.secondAirportCode,
        chapterName: c.title,
        nationalID: c.nationalID,
      })),
    };
    hits += searchChapter.data.length;
  }

  if (searchNational.data && searchNational.data.length) {
    results.nationals = {
      name: "Nationals",
      results: searchNational.data.map((c) => ({
        key: c.id,
        url: c.url,
        title: c.title,
        description: c.id,
        id: c.id,
        affiliateCode: c.affiliate,
        region: c.region,
        yearFounded: c.yearFounded,
        email: c.email,
        website: c.website,
        nationalName: c.title,
        countryID: c.id,
      })),
    };
    hits += searchNational.data.length;
  }

  if (searchUser.data && searchUser.data.length) {
    results.peoples = {
      name: "People",
      results: searchUser.data.map((c) => ({
        key: c.id,
        url: c.url,
        title: c.title,
        description: c.chapter,
        id: c.id,
        displayName: c.title,
        chapterName: c.chapter,
        nationalName: c.national,
        nationalID: c.nationalID,
        azureLogin: c.azureLogin,
      })),
    };
    hits += searchUser.data.length;
  }

  if (searchProgramme.data && searchProgramme.data.length) {
    results.programmes = {
      name: "Programmes",
      results: searchProgramme.data.map((c) => ({
        key: c.id,
        url: c.url,
        title: c.id,
        description: c.title,
        id: c.id,
        type: c.type,
        chapterName: c.title,
        nationalID: c.nationalID,
        minAge: c.minAge,
        maxAge: c.maxAge,
        referenceNo: c.id,
        urls: c.urLs,
      })),
    };
    hits += searchProgramme.data.length;
  }

  if (resources && resources.results) {
    results.resources = resources;
    hits += resources.results.length;
  }

  if (media && media.results) {
    results.medias = media;
    hits += media.results.length;
  }

  return (
    <SearchCtx.Provider
      value={{
        query,
        topicArea,
        typeOfDoc,
        keywords,
        result: results,
        resourceYear: year,
        setResourceYear,
        setFilter,
        setTopicArea,
        setTypeOfDoc,
        setKeywords,
        mediaTag,
        mediaYear,
        programmeType,
        setMediaTag,
        setMediaYear,
        setProgrammeType,
        search: setQuery,
        openResultPage: () => {
          history.push("/search");
          setOpen(false);
        },
        loading,
        open,
        hits,
        filter,
        resetSearch,
      }}
    >
      {props.children}
    </SearchCtx.Provider>
  );
};
