/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * Fluent
 *
 * Good
 *
 * Fair
 */
export enum EFluency {
    Fluent = 'Fluent',
    Good = 'Good',
    Fair = 'Fair',
}
