import * as React from "react";
import { Route } from "react-router-dom";
import { NavMenu } from "../../components/NavMenu";
import { Row, Col } from "react-flexbox-grid";
import { SideMenu } from "../../components/SideMenu";
import { Footer } from "../../components/Footer";
import useUser from "../../hooks/UseUser";
import { LoadingScreen } from "../LoadingScreen";
import { IMenuItem } from "../../routes";
import { Container, Segment } from "semantic-ui-react";
import { MainContent } from "./MainContent";
import "../../styles/Global.css";
import "../../styles/print.css";
import { Media } from "../../AppMedia";

interface AnonLayoutProps {
  children?: React.ReactNode;
  loading?: boolean;
  loggedIn: boolean;
  path: string;
  name: string;
}

export const AnonLayout = ({
  loading,
  children,
  loggedIn,
  path,
  name,
}: AnonLayoutProps) => {
  return (
    <div>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="layout-main">
          <NavMenu anonymous={!loggedIn} />
          <Container className="m-0" fluid>
            <Row className="layout-content">
              <Media greaterThanOrEqual="computer">
                {(className, renderChildren) =>
                  renderChildren && (
                    <>
                      {loggedIn && (
                        <Col md={2} className="p-0 hide-print">
                          <SideMenu />
                        </Col>
                      )}
                      <Col md={!loggedIn || 10}>
                        <MainContent title={name}>{children}</MainContent>
                      </Col>
                    </>
                  )
                }
              </Media>
              <Media at="mobile">
                {(className, renderChildren) =>
                  renderChildren && (
                    <Col md>
                      <MainContent title={name}>{children}</MainContent>
                    </Col>
                  )
                }
              </Media>
            </Row>
          </Container>
        </div>
      )}
      <Media greaterThanOrEqual="computer">
        <Footer />
      </Media>
      <Media at="mobile">
        <div style={{ height: "2em" }} />
      </Media>
    </div>
  );
};

export const AnonLayoutRoute = ({
  component,
  render,
  path,
  urlParam,
  ...rest
}: IMenuItem) => {
  const userCtx = useUser();
  const RouteComp: any = component;
  return (
    <Route
      {...rest}
      path={path}
      render={(matchProps) => (
        <AnonLayout
          name={rest.name}
          loggedIn={!!userCtx.user}
          path={path as string}
        >
          {render ? render(matchProps) : <RouteComp {...matchProps} />}
        </AnonLayout>
      )}
    />
  );
};
