import * as React from "react";

export default function cancelForm(dirty: boolean, submit: boolean, props: any, execute?: boolean) {
  let isSubmitting = submit;
/*  console.log(dirty, ': dirty', submit, ':submit', props, ':props', execute, ':execute');*/
  if (dirty && !isSubmitting) {
    let isSubmitting = window.confirm("You have unsaved changes!");
    if (isSubmitting) {
      if (execute) {
        props.reload && props.reload();
        props.refetch && props.refetch();
      }
      else {
        props.reload && props.reload();
        props.refetch && props.refetch();
      }
      return !isSubmitting;
    }
    else {
      return !isSubmitting;
    }
  }
  else {
    if (execute) {
      props.reload && props.reload();
      props.refetch && props.refetch();
    }
    else {
      props.reload && props.reload();
      props.refetch && props.refetch();
    }
    return dirty;
  }
};
