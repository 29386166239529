import * as React from "react";
import {
  Button,
  Header,
  Icon,
  Label,
  Popup,
  Segment,
  Table,
  DropdownItemProps,
  Flag,
  CardContent,
} from "semantic-ui-react";
import "../../styles/Utils.css";
import { DataTableColumn, DataTable } from "../../components/DataTable";

interface ISQLQueryResult {
  values?: object[][] | undefined | null;
  columns?: string[] | null;
  exportPostfix: string;
  loading: boolean;
}

export const SQLQueryTable: React.FC<ISQLQueryResult> = (
  props: ISQLQueryResult
) => {
  let columns: DataTableColumn<object[]>[] = [];
  let counter = props.columns ? props.columns.length : 0;
  for (var col = 0; col < counter; col++) {
    let index = col;
    let column: DataTableColumn<object[]> = {
      id: props.columns && props.columns[col],
      Header: props.columns && props.columns[col],
      accessor: (d: object[]) => d[index],
      width: 100,
    } as any;
    columns.push(column);
  }

  return (
    <div>
      <DataTable
        header={<Header floated="left">Query result</Header>}
        exportFileName={
          props.exportPostfix ? "Result of " + props.exportPostfix : "Result"
        }
        loading={props.loading}
        scrollHorizontal={true}
        columns={columns}
        data={props.values ? props.values : []}
        buttonStyle={{ padding: "11px 10px" }}
      />
    </div>
  );
};
