/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Source folder of document
 *
 * WebApp
 *
 * Resource
 *
 * Programme
 *
 * ProgrammeYear
 *
 * ProgrammeType
 *
 * ProgrammeFolder
 */
export enum EInfoURLSourceLocation {
    WebApp = 'WebApp',
    Resource = 'Resource',
    Programme = 'Programme',
    ProgrammeYear = 'ProgrammeYear',
    ProgrammeType = 'ProgrammeType',
    ProgrammeFolder = 'ProgrammeFolder',
}
