import * as React from "react";
import { Menu, Icon } from "semantic-ui-react";
import "./MobileBottomNav.less";
import { Link } from "react-router-dom";

interface IMobileBottomNavProps {
  onToggleMenu: () => void;
  menuOpen: boolean;
}

export const MobileBottomNav: React.FC<IMobileBottomNavProps> = props => {
  return (
    <Menu className="bottom-nav" pointing secondary fixed="bottom">
      <Menu.Item as={Link} to="/profile">
        <Icon name="home" />
      </Menu.Item>
      <Menu.Item as={Link} to="/search" icon="search" />
      <Menu.Menu position="right">
        <Menu.Item
          icon="bars"
          onClick={props.onToggleMenu}
          active={props.menuOpen}
        />
      </Menu.Menu>
    </Menu>
  );
};
