import * as React from "react";
import { AnonLayoutRoute } from "./layouts/AnonLayout";
import useMessages from "../hooks/useMessages";
import { Redirect, useLocation } from "react-router-dom";

interface Props {}

export default function NotFoundRoute({}: Props) {
  return <AnonLayoutRoute name="Page not found" component={NotFound} />;
}

function NotFound({}: Props) {
  const msg = useMessages();
  const loc = useLocation();
  msg.addMessage(
    {
      icon: "meh outline",
      header: (
        <div>
          We couldn't find anything at <strong>{loc.pathname}</strong>
        </div>
      ),
    },
    "error"
  );
  return <Redirect to="/" />;
}
