import * as React from "react";
import { MobileBottomNav } from "./MobileBottomNav";
import { SideOverlay } from "./SideOverlay";
import useNavigation from "../hooks/UseNavigation";
import useUser from "../hooks/UseUser";
import useRights from "../hooks/UseRights";
import { ReactComponent as Logo } from "../img/logo.svg";
import { SideMenu } from "./SideMenu";
import { Menu } from "semantic-ui-react";

interface IMobileNavigationProps {}

export const MobileNavigation: React.FC<IMobileNavigationProps> = React.memo(
  props => {
    const nav = useNavigation();
    const userCtx = useUser();
    const rightsCtx = useRights();
    if (!userCtx.user) {
      return null;
    }
    return (
      <>
        <MobileBottomNav
          menuOpen={nav.menuVisible}
          onToggleMenu={nav.toggleMenu}
        />
        <SideOverlay
          vertical
          dimmed
          onHide={nav.toggleMenu}
          visible={nav.menuVisible}
        >
          <SideMenu
            mobile
            top={
              <Menu.Item style={{ height: "4em", padding: "0.5em" }}>
                <Logo />
              </Menu.Item>
            }
          />
        </SideOverlay>
      </>
    );
  }
);
