// import { UserApi, Configuration } from "../generated";
// import { fetchApi } from "./ApiErrorFetch";

import { UserService } from "../generated";

// const User = new UserApi(
//   new Configuration({ basePath: window.location.origin, fetchApi: fetchApi })
// );

export default UserService;
