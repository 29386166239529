import * as React from "react";
import { ErrorBoundary } from "../../components/ErrorBoundary";

interface MainContentProps {
  title?: string | null;
  children?: React.ReactNode;
}

export const MainContent = ({ children, title }: MainContentProps) => {
  React.useEffect(() => {
    document.title = title ? title + " - myCISV" : "myCISV";
    return () => {};
  }, [title]);
  return (
    <ErrorBoundary>
      <div className="App main-content">{children}</div>
    </ErrorBoundary>
  );
};
