import _ from "lodash";
import * as React from "react";
import { Search, SearchProps } from "semantic-ui-react";
import useUser from "../hooks/UseUser";
import { ELoginStatus } from "../generated";

interface SearchInputProps extends SearchProps {
  loading?: boolean;
  onEnter?: () => void;
  onSearch: (value: string) => void;
}



export const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, ...props }) => {
  const user = useUser();
  const [query, setQuery] = React.useState(value);
  const change = React.useCallback(_.debounce(
    (nvalue?: any) => props.onSearch(nvalue),
    500,
    {
      leading: false,
      trailing: true
    }
  ),[]);
  return (
    <Search
      input={{ icon: "search", placeholder: "Search", disabled: user.user ? !(user.user.status === ELoginStatus.OK) : undefined }}
      onSearchChange={(e: any, { value }: { value?: any }) => {
        change(value);
        setQuery(value);
        }
      }
      onKeyPress={(event: KeyboardEvent) =>
        event.key === "Enter" && props.onEnter && props.onEnter()
      }
      noResultsMessage={props.loading ? "Loading..." : "No results found"}
      value={query}
      {...props}
    />
  );
});

export default SearchInput;
