/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Rights_User {
    Rights = 'User.',
    Edit = 'User.Edit',
    View = 'User.View',
    ViewBase = 'User.ViewBase',
    ViewSensitive = 'User.ViewSensitive',
    EditRedFlag = 'User.EditRedFlag',
    ViewRedFlag = 'User.ViewRedFlag',
}
