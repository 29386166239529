import * as React from "react";
import SearchApi from "../api/SearchApi";
import { useQuery } from "@tanstack/react-query";
import { cancelPromise } from "../utils/withAbortSignal";

export interface SearchResourceQuery {
  queryString?: string | null;
  entriesToTake?: number;
  keywords?: string | null;
  topicArea?: string | null;
  typeOfDoc?: string | null;
  year?: string | null;
}

export default function useResourcesSearch({
  queryString,
  entriesToTake,
}: SearchResourceQuery) {
  const [topicArea, setTopicArea] = React.useState("");
  const [typeOfDoc, setTypeOfDoc] = React.useState("");
  const [keywords, setKeywords] = React.useState("");
  const [year, setResourceYear] = React.useState("");
  const searchResource = useQuery(
    [
      "searchResourceGET",
      queryString,
      topicArea,
      typeOfDoc,
      keywords,
      year,
      entriesToTake,
    ],
    ({ signal }) =>
      cancelPromise(
        SearchApi.searchResourceGet(
          queryString!,
          entriesToTake,
          keywords,
          topicArea,
          typeOfDoc,
          year
        ),
        signal
      ),
    { enabled: !!queryString }
  );
  return {
    resources: searchResource.data && {
      name: "Resources",
      results: searchResource.data.map((c) => {
        const type =
          c.additionalFields &&
          c.additionalFields.find((res) => res.id === "Type of doc");
        return {
          key: c.url,
          url: c.url,
          description: (type && type.description) || c.mimeType,
          id: c.url,
          title: c.displayName,
          sourceLocation: c.sourceLocation,
          mimeType: c.mimeType,
          additionalFields: c.additionalFields,
        };
      }),
    },
    loading: searchResource.isInitialLoading,
    setTopicArea,
    setTypeOfDoc,
    setKeywords,
    setResourceYear,
    topicArea,
    typeOfDoc,
    keywords,
    year,
  };
}
