import { CancelablePromise } from "../generated";

/**
 * wrap a promise with an abort signal,
 * used for request canceling with react-query
 * @param getPromise
 */
export function withAbortSignal<T>(
  getPromise: (signal: AbortSignal) => Promise<T>
): Promise<T> {
  // Create a new AbortController instance for this request
  const controller = new AbortController();
  // Get the abortController's signal
  const signal = controller.signal;
  const promise = getPromise(signal);
  // Cancel the request if React Query calls the `promise.cancel` method
  (promise as any).cancel = () => controller.abort();
  return promise;
}

export function cancelPromise<T>(
  p: CancelablePromise<T>,
  signal?: AbortSignal
): CancelablePromise<T> {
  signal?.addEventListener("abort", () => {
    p.cancel();
  });
  return p;
}
