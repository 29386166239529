import React from "react"
import { Segment, Container, Grid, List, Header, Divider, Image } from "semantic-ui-react";
import { ReactComponent as CisvLogo } from "../img/logo_white.svg";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";

interface FooterProps {

}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <Segment inverted color="blue" vertical style={{ padding: "2em 0em", height: "10em" }} className="hide-print">
      <Container textAlign="center">
        <Row center="xs">
          <Col xs={5} lg={2}>
            <CisvLogo />
          </Col>
        </Row>
        <List horizontal inverted divided link size="small">
          <List.Item as="a" target="_blank" href="https://cisv.org/contact-us/">
            Contact Us
                </List.Item>
          <List.Item as={Link} to="/content/terms-and-conditions/">
            Terms and Conditions
                </List.Item>
          <List.Item as={Link} to="/content/privacy-policy/">
            Privacy Policy
                </List.Item>
        </List>
      </Container>
    </Segment>
  )
}
