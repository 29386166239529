import * as React from 'react'
import { Dimmer, Loader } from "semantic-ui-react";
import { ReactComponent as CisvLogo } from "../img/logo.svg";

interface Props {
    
}

export const LoadingScreen: React.FC<Props> = () => {
    return (
      <Dimmer active inverted>
        <Loader size='massive'><CisvLogo /></Loader>
      </Dimmer>
    )
}
