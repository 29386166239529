/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Rights_WebApp {
    Rights = 'WebApp.',
    Admin = 'WebApp.Admin',
    GlobalAdmin = 'WebApp.Admin.Global',
    SyncAdmin = 'WebApp.Admin.Sync',
    RolesAdmin = 'WebApp.Admin.Roles',
    Finance = 'WebApp.Finance',
    FinanceEdit = 'WebApp.Finance.Edit',
    FinanceView = 'WebApp.Finance.View',
    ManageDirectory = 'WebApp.Manage.Directory',
    ViewAllUsers = 'WebApp.ViewAllUsers',
    Demo = 'WebApp.Demo',
    GlobalAdminRoleID = 'ba48fe73-246b-483a-99a0-a661361ba124',
}
