/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * None
 *
 * Male
 *
 * Female
 *
 * Custom
 */
export enum EGender {
    None = 'None',
    Male = 'Male',
    Female = 'Female',
    Custom = 'Custom',
}
