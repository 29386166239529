import * as React from "react";
import { Menu, Accordion } from "semantic-ui-react";
import "./SideMenu.less";
import useNavigation from "../hooks/UseNavigation";
import useRights from "../hooks/UseRights";
import { renderMenu } from "../context/NavigationContext";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { LoadableComponent } from "@loadable/component";
import { IMenuItem } from "../routes";
import useUser from "../hooks/UseUser";

interface ISideMenuProps {
  mobile?: boolean;
  top?: JSX.Element;
}

export const SideMenu: React.FC<ISideMenuProps> = (props) => {
  const navCtx = useNavigation();
  const userCtx = useUser();
  const rightsCtx = useRights();
  const preload = React.useCallback((route: IMenuItem) => {
    route.component &&
      (route.component as LoadableComponent<any>).preload &&
      (route.component as LoadableComponent<any>).preload();
  }, []);
  return (
    <Accordion
      as={Menu}
      vertical
      className={classNames("side-menu", { mobile: props.mobile })}
    >
      {props.top}
      {navCtx.routes
        .filter(
          (r) =>
            !r.accessRight ||
            r.accessRight.some((right) => rightsCtx.hasRight(right))
        )
        .map((route, i) => (
          <Menu.Item className="route-item" key={route.name}>
            <Accordion.Title
              className="item"
              as={Link}
              onMouseEnter={() => preload(route)}
              icon={route.icon}
              to={route.path}
              active={route.active}
              content={route.name}
              index={i}
            />
            <Accordion.Content
              as={Menu.Menu}
              active={route.active}
              content={
                route.submenu &&
                renderMenu(
                  route.submenu,
                  window.location.pathname,
                  rightsCtx.hasRight,
                  preload
                )
              }
            />
          </Menu.Item>
        ))}
      {props.mobile && (
        <Menu.Item className="route-item" onClick={() => userCtx.logout()}>
          <Accordion.Title
            className="item"
            content="Sign out"
            icon="sign-out"
          />
        </Menu.Item>
      )}
    </Accordion>
  );
};
