export const FETCH_API_ERROR = "FETCH-API-ERROR";
export const FETCH_API_WARNING = "FETCH-API-WARNING";
export function EmitErrorEvent(error: string) {
  const apiErrorEvent = new CustomEvent(FETCH_API_ERROR, {
    detail: error,
  });
  window.dispatchEvent(apiErrorEvent);
}
export function EmitWarningEvent(warning: string) {
  const apiWarningEvent = new CustomEvent(FETCH_API_WARNING, {
    detail: warning,
  });
  window.dispatchEvent(apiWarningEvent);
}
